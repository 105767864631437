import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About rekas" />
    <h1>About Rekas</h1>
    <p>I'm Reka, a young woman in love with crafts, all things handmade and creative.</p>
    <p>I started crocheting long years back. My mom taught me some basics but I never made any projects really, just playing around with yarn, chaining and stitching - basically not disturbing my parents.</p>
    <p>Then I abandoned crocheting, as there seemed to be no point (yet again, without any actual projects) all up until the 2020 qurantine when I needed a creative outlet (who didn't... - one of my friends started reading more, another started to do embroidery, etc).</p>
    <p>I picked up crocheting again, and lo-and-behold, I now have a large yarn stash, a larger WIP pile, and more ideas than I could ever count.</p>
    <p>I'll use this platform to show my progress, my plans, my ideas, and hopefully (eventually), I'll also be able to create my own patterns.. maybe even a full-time crochet business?</p>
    <p>Let's hope, work for it, and see where it all goes.</p>
    <p>Let me know if there's a pattern I should check out, I'm always looking for more ideas!</p>
    <p>You can also find me on
        <br/> - <a href="https://www.instagram.com/madebyrekas/" target={'_blank'} rel={'noopener noreferrer'}>Instagram</a>
        <br/> - <a href="https://www.pinterest.com/madebyrekas/" target={'_blank'} rel={'noopener noreferrer'}>Pinterest</a>


    </p>
  </Layout>
)

export default About
